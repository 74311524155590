import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" description="You'll find my privacy information, so you'll know which data I'm collecing to run my business." keywords={[`User Experience Designer`, `UX`, `UI`, `Interactive Design`, `Interactive Media Design`, `Design Thinking Darmstadt`, `Danny Giebe`, `Front-end Developer`, `Interdisciplinary Designer`, `Design Consulting`, `Design Thinking Workshop Darmstadt`, `IoT Designer`]} />
    <section>
      <h2>Datenschutzerklärung</h2>
      <h4>Geltungsbereich</h4>
      <p>
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten,
        Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser
        Social Media Profile auf (nachfolgend gemeinsam bezeichnet als
        „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie
        z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die
        Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
      </p>
      <h4>Verantwortlicher</h4>
      <address>
        <p>
          Danny Giebe <br />Goethestr. 3<br />
          64807 Dieburg<br />
          Germany
        </p>
      </address>

      <h4>Arten der verarbeiteten Daten:</h4>

      <ul>
        <li>– Bestandsdaten (z.B., Namen, Adressen).</li>
        <li>– Kontaktdaten (z.B., E-Mail, Telefonnummern).</li>
        <li>– Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</li>
        <li>
          – Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten).
        </li>
        <li>
          – Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
        </li>
      </ul>

      <h4>Kategorien betroffener Personen</h4>

      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>

      <h4>Zweck der Verarbeitung</h4>

      <ul>
        <li>
          – Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
          Inhalte.
        </li>
        <li>
          – Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
        </li>
        <li>– Sicherheitsmaßnahmen.</li>
        <li>– Reichweitenmessung/Marketing</li>
      </ul>
      <h4>Maßgebliche Rechtsgrundlagen</h4>

      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
        Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
        Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1
        lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur
        Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen
        sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die
        Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen
        Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage
        für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist
        Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen
        der betroffenen Person oder einer anderen natürlichen Person eine
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
        Abs. 1 lit. d DSGVO als Rechtsgrundlage.
      </p>

      <h4>Sicherheitsmaßnahmen</h4>

      <p>
        Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des
        Stands der Technik, der Implementierungskosten und der Art, des Umfangs,
        der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen
        Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und
        Freiheiten natürlicher Personen, geeignete technische und
        organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau
        zu gewährleisten.
      </p>

      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
        Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
        Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von
        Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
        Daten gewährleisten. Ferner berücksichtigen wir den Schutz
        personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von
        Hardware, Software sowie Verfahren, entsprechend dem Prinzip des
        Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
        Voreinstellungen (Art. 25 DSGVO).
      </p>

      <h4>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h4>

      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren,
        sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten
        gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis
        (z.B. wenn eine Übermittlung der Daten an Dritte, wie an
        Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur
        Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine
        rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer
        berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern,
        etc.).
      </p>

      <p>
        Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
        sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf
        Grundlage des Art. 28 DSGVO.
      </p>

      <h4>Übermittlungen in Drittländer</h4>

      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten
        oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
        Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt
        dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten,
        auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen
        Verpflichtung oder auf Grundlage unserer berechtigten Interessen
        geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse,
        verarbeiten oder lassen wir die Daten in einem Drittland nur beim
        Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO
        verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer
        Garantien, wie der offiziell anerkannten Feststellung eines der EU
        entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy
        Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
        Verpflichtungen (so genannte „Standardvertragsklauseln“).
      </p>

      <h4>Rechte der betroffenen Personen</h4>

      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
        sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15
        DSGVO.
      </p>

      <p>
        Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung
        der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden
        unrichtigen Daten zu verlangen.
      </p>

      <p>
        Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass
        betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
        Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten
        zu verlangen.
      </p>

      <p>
        Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die
        Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten
        und deren Übermittlung an andere Verantwortliche zu fordern.
      </p>

      <p>
        Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der
        zuständigen Aufsichtsbehörde einzureichen.
      </p>

      <h4>Widerrufsrecht</h4>

      <p>
        Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO
        mit Wirkung für die Zukunft zu widerrufen
      </p>

      <h4>Widerspruchsrecht</h4>

      <p>
        Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
        Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann
        insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
        erfolgen.
      </p>

      <h4>Löschung von Daten</h4>

      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18
        DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im
        Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
        nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
        Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.
        die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
        gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
        aufbewahrt werden müssen.
      </p>

      <p>
        Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung
        insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und
        4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege,
        Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre
        gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe).
      </p>

      <p>
        Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
        insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen,
        Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der
        Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit
        Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit
        elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und
        Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten
        erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch
        genommen wird.
      </p>

      <h4>Geschäftsbezogene Verarbeitung</h4>

      <p>Zusätzlich verarbeiten wir</p>
      <ul>
        <li>
          – Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit, Kundenkategorie).
        </li>
        <li>
          – Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie) von unseren
          Kunden, Interessenten und Geschäftspartner zwecks Erbringung
          vertraglicher Leistungen, Service und Kundenpflege.
        </li>
      </ul>

      <h4>Agenturdienstleistungen</h4>

      <p>
        Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen
        Leistungen zu denen konzeptionelle und strategische Beratung,
        Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege,
        Umsetzung von Kampagnen und Prozessen/ Handling, Serveradministration,
        Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören.
      </p>

      <p>
        Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen
        oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern),
        Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten
        (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B.,
        Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im
        Rahmen der Auswertung und Erfolgsmessung von Marketingmaßnahmen).
        Besondere Kategorien personenbezogener Daten verarbeiten wir
        grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten
        Verarbeitung sind. Zu den Betroffenen gehören unsere Kunden,
        Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder
        Mitarbeiter sowie Dritte. Der Zweck der Verarbeitung besteht in der
        Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice.
        Die Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1
        lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO
        (Analyse, Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten
        Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen
        erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe hin.
        Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines
        Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines
        Auftrags überlassenen Daten handeln wir entsprechend den Weisungen der
        Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung
        gem. Art. 28 DSGVO und verarbeiten die Daten zu keinen anderen, als den
        auftragsgemäßen Zwecken.
      </p>

      <p>
        Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
        vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der
        Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen
        Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J, gem.
        § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von Daten, die
        uns gegenüber im Rahmen eines Auftrags durch den Auftraggeber
        offengelegt wurden, löschen wir die Daten entsprechend den Vorgaben des
        Auftrags, grundsätzlich nach Ende des Auftrags.
      </p>

      <h4>
        Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
      </h4>

      <p>
        Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
        Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
        gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten
        wir dieselben Daten, die wir im Rahmen der Erbringung unserer
        vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind
        Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der
        Verarbeitung sind Kunden, Interessenten, Geschäftspartner und
        Websitebesucher betroffen. Der Zweck und unser Interesse an der
        Verarbeitung liegt in der Administration, Finanzbuchhaltung,
        Büroorganisation, Archivierung von Daten, also Aufgaben die der
        Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer
        Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der
        Daten im Hinblick auf vertragliche Leistungen und die vertragliche
        Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
        genannten Angaben.
      </p>

      <p>
        Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung,
        Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere
        Gebührenstellen und Zahlungsdienstleister.
      </p>

      <p>
        Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen
        Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen
        Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese
        mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich
        dauerhaft.
      </p>

      <h4>Kontaktaufnahme</h4>

      <p>
        Bei der Kontaktaufnahme mit dem Anbieter (zum Beispiel per
        Kontaktformular oder E-Mail) werden die Angaben des Nutzers zwecks
        Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen
        entstehen, gespeichert.
      </p>

      <p>
        Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben
        veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und
        Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht
        ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche
        Schritte gegen die Versender von sogenannten Spam-Mails bei Verstößen
        gegen dieses Verbot sind ausdrücklich vorbehalten.
      </p>

      <p>
        Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können ihre
        IP-Adressen auf Grundlage unserer berechtigten Interessen im Sinne des
        Art. 6 Abs. 1 lit. f. DSGVO für 7 Tage gespeichert werden. Das erfolgt
        zu unserer Sicherheit, falls jemand in Kommentaren und Beiträgen
        widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische
        Propaganda, etc.). In diesem Fall können wir selbst für den Kommentar
        oder Beitrag belangt werden und sind daher an der Identität des
        Verfassers interessiert.
      </p>

      <p>
        Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten
        Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO, die Angaben der Nutzer
        zwecks Spamerkennung zu verarbeiten.
      </p>

      <p>
        Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen
        die IP-Adressen der Nutzer für deren Dauer zu speichern und Cookis zu
        verwenden, um Mehrfachabstimmungen zu vermeiden.
      </p>

      <p>
        Die im Rahmen der Kommentare und Beiträge angegebenen Daten, werden von
        uns bis zum Widerspruch der Nutzer dauerhaft gespeichert.
      </p>

      <h4>Hosting und E-Mail-Versand</h4>

      <p>
        Für das Hosting wird uberspace verwendet. Mit dem Hostinganbieter wurde
        ein Auftragsverarbeitungsvertrag geschlossen.
      </p>

      <p>
        Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
        Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
        Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
        Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
        Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
        Onlineangebotes einsetzen.
      </p>

      <p>
        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
        Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
        Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
        Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
        effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
        gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
        Auftragsverarbeitungsvertrag).
      </p>

      <h4>Erhebung von Zugriffsdaten und Logfiles</h4>

      <p>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Die IP Adressen für den Zugriff werden vom
        Hostinganbieter gekürzt und erlauben keine personenbezogenen
        Informationen mehr.
      </p>
    </section>
  </Layout>
)

export default PrivacyPage
